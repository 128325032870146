const initialState = {
  isFetchingEmployeeData: false,
  isFetchingReviewCycles: false,
  isEmployeeCompetencyError: {},
  employeeData: {},
  reviewCycleOptions: {},
  competencyScores: {},
};

const employee = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_IS_FETCHING_EMPLOYEE_DATA": {
      return {
        ...state,
        isFetchingEmployeeData: !state.isFetchingEmployeeData,
      };
    }
    case "TOGGLE_IS_FETCHING_REVIEW_CYCLES": {
      return {
        ...state,
        isFetchingReviewCycles: !state.isFetchingReviewCycles,
      };
    }
    case "SET_EMPLOYEE_COMPETENCY_DATA": {
      return {
        ...state,
        employeeData: action.payload,
      };
    }
    case "SET_EMPLOYEE_COMPETENCY_ERROR": {
      return {
        ...state,
        isEmployeeCompetencyError: action.payload,
      };
    }
    case "SET_REVIEW_CYCLE_OPTIONS": {
      return {
        ...state,
        reviewCycleOptions: action.payload,
      };
    }
    case "SET_COMPETENCIES_SCORES": {
      return {
        ...state,
        competencyScores: action.payload,
      };
    }
    default:
      return state;
  }
};

export default employee;
