import {
  UPDATE_CYCLE_FILTER_ATTRIBUTES,
  REMOVE_CYCLE_FILTERS,
  UPDATE_PAGE_FILTER,
  REMOVE_PAGE_FILTER,
} from "actions/constants";

const initialState = {
  filter_view_type: "global",
  page_filter: {},
  cycle_view: {},
};

const goalsAttributeFilters = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAGE_FILTER: {
      const { data, goalType, goalTypeId } = action.payload;
      const { cycles } = data;
      const { page_filter } = state;
      const newState = {
        ...state,
        page_filter: {
          ...page_filter,
          [goalType]: {
            ...page_filter?.[goalType],
            [goalTypeId]: {
              ...page_filter?.[goalType]?.[goalTypeId],
              ...data,
              cycles: cycles || page_filter?.[goalType]?.[goalTypeId]?.cycles,
            },
          },
        },
      };
      return newState;
    }

    case REMOVE_PAGE_FILTER: {
      const { goalType, goalTypeId } = action.payload;
      const { page_filter } = state;
      const newState = {
        ...state,
        page_filter: {
          ...page_filter,
          [goalType]: {
            ...page_filter?.[goalType],
            [goalTypeId]: {
              cycles: page_filter?.[goalType]?.[goalTypeId]?.cycles,
            },
          },
        },
      };
      return newState;
    }

    case UPDATE_CYCLE_FILTER_ATTRIBUTES: {
      const { data, goalType, goalTypeId } = action.payload;
      const { cycle_view } = state;
      const newState = {
        ...state,
        cycle_view: {
          ...cycle_view,
          [goalType]: {
            ...cycle_view?.[goalType],
            [goalTypeId]: { ...cycle_view?.[goalType]?.[goalTypeId], ...data },
          },
        },
      };
      return newState;
    }

    case REMOVE_CYCLE_FILTERS: {
      const { goalType, goalTypeId, cycleId } = action.payload;
      const newState = { ...state };
      delete newState.cycle_view?.[goalType]?.[goalTypeId]?.[cycleId];
      return newState;
    }
    default:
      return state;
  }
};

export default goalsAttributeFilters;
