export const SET_EXPANDED_KEYS_STORE = "SET_EXPANDED_KEYS_STORE";
export const SET_CHECKIN_CARD_ID = "SET_CHECKIN_CARD_ID";
export const ADD_EXPANDED_KEYS_STORE = "ADD_EXPANDED_KEYS_STORE";
export const REMOVE_EXPANDED_KEYS_STORE = "REMOVE_EXPANDED_KEYS_STORE";
export const RESET_EXPANDED_KEYS_STORE = "RESET_EXPANDED_KEYS_STORE";
export const ADD_LOADING_KEY = "ADD_LOADING_KEY";
export const REMOVE_LOADING_KEY = "REMOVE_LOADING_KEY";
export const SET_SELECTED_CYCLE_ID = "SET_SELECTED_CYCLE_ID";
export const SET_RECENT_KEY_RESULT_ID = "SET_RECENT_KEY_RESULT_ID";
export const SET_SYNC_PROJECT_STATUS = "SET_SYNC_PROJECT_STATUS";
export const SET_UPDATED_ALIGN_PARENT_NODE_ID =
  "SET_UPDATED_ALIGN_PARENT_NODE_ID";
export const SET_ALIGN_PREVIOUS_PARENT_NODE_ID =
  "SET_ALIGN_PREVIOUS_PARENT_NODE_ID";
export const SET_CREATE_GOAL_DETAIL_PANEL = "SET_CREATE_GOAL_DETAIL_PANEL";
export const SET_CHECKIN_DRAGGER_VARIABLE = "SET_CHECKIN_DRAGGER_VARIABLE";
export const SET_OPEN_GOALS_OKR_POPUP = "SET_OPEN_GOALS_OKR_POPUP";
export const FETCH_OKR_TAGS = "FETCH_OKR_TAGS";
